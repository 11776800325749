/* globals.css */
body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3 {
  font-family: "Roboto", sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
